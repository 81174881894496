<template>
  <div class="fluid" id="attendanceReport">
    <div>
      <div class="attendance-list-div">
        <v-card class="px-5 py-3">
          <v-row>
            <v-col cols="12">
              <div>
                <v-toolbar
                  class="attendance-list-toolbar-1"
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0"
                >
                  <v-toolbar-title>Access Door Report</v-toolbar-title>
                  <v-divider class="mx-6" inset vertical></v-divider>

                  <div style="width:20%; margin-right:10px;">
                    <v-text-field
                      class="attendance-start-date ml-1 mr-2"
                      dense
                      label="Start Date"
                      type="date"
                      name="startdate"
                      style="height:30px;"
                      step="1"
                      v-model="start_date"
                    />
                  </div>

                  <div style="width:20%;">
                    <v-text-field
                      class="attendance-start-date ml-1 mr-2"
                      dense
                      label="End Date"
                      type="date"
                      name="enddate"
                      style="height:30px;"
                      step="1"
                      v-model="end_date"
                    />
                  </div>
                  <div style="width:210px">
                    <v-select
                      :value="2"
                      style=" height:30px;
                        position: relative;
                        top:3px;
                        margin-left: 10px;
                        font-size: 16px;
                      "
                      :items="dropDownCompany"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      v-model="company"
                    ></v-select>
                  </div>
                  <!-- <div class="attendance-list-toolbar-div"> -->
                  <!-- </div> -->
                </v-toolbar>
                <v-toolbar
                  class="attendance-list-toolbar-1"
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0"
                >
                  <v-toolbar-title style="color:transparent;"
                    >Access Door Report</v-toolbar-title
                  >
                  <v-divider
                    class="mx-6"
                    inset
                    vertical
                    style="border:1px solid transparent;"
                  ></v-divider>
                  <!-- <div v-if="isAdmin" style="width: 150px">
                    <v-btn
                      elevation="2"
                      tile
                      small
                      color="black"
                      class="white--text"
                      >Export
                      <v-icon small style="margin-left:5px;"
                        >mdi-download</v-icon
                      ></v-btn
                    >
                  </div> -->
                  <div class="attendance-list-toolbar-div">
                    <v-text-field
                      v-model="paramAPI.keyword"
                      label="search here"
                      type="search"
                      append-icon="mdi-magnify"
                      outlined
                      dense
                      @keyup.enter="searchEnter"
                      style="position: relative; top: 15px; width: 10px"
                    ></v-text-field>
                    <div
                      style=" padding: 10px; display:flex;justify-content:center;align-items:center;"
                    >
                      <v-btn
                        elevation="2"
                        tile
                        large
                        color="black"
                        class="white--text"
                        style="position:relative; left:15px;"
                        @click="searchEnter"
                        >search</v-btn
                      >
                    </div>
                  </div>
                </v-toolbar>
              </div>
              <v-toolbar
                class="attendance-list-toolbar-2"
                flat
                style="
                  height: 60px;
                  border-radius: 10px 10px 0 0;
                  border-bottom: 1px solid #e0e0e0;
                "
              >
                <v-text-field
                  v-model="paramAPI.keyword"
                  label="Search here"
                  type="search"
                  outlined
                  dense
                  @keyup.enter="searchEnter"
                  style="position: relative; top: 15px; width: 10px"
                ></v-text-field>
              </v-toolbar>
            </v-col>

            <v-col cols="12">
              <v-data-table
                fixed-header
                height="57vh"
                style="cursor: pointer;"
                :headers="headers"
                :items="result"
                :items-per-page="10"
                :loading="loading"
                @click:row="rowClick"
                item-key="id"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'dooraccessreport',
  data: () => ({
    attendance: buildType.apiURL('attendance'),
    isAdmin: true,
    company: 0,
    paramAPI: {
      keyword: ''
    },
    headers: [
      {
        text: 'Log Time',
        value: 'attendance_time',
        align: 'left',
        sortable: false
      },
      {
        text: 'NIK',
        value: 'nik',
        align: 'left',
        sortable: false
      },
      {
        text: 'Name',
        value: 'employee_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Company',
        value: 'company_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Door',
        value: 'door_name',
        align: 'center',
        sortable: false
      }
    ],
    result: [],
    loading: false,
    start_date: '',
    end_date: '',
    dropDownCompany: []

    // excel_export_fields: {
    //   'Log Time': 'attendance_time',
    //   NIK: 'employee_code',
    //   Name: 'employee_name',
    //   Mask: 'use_mask',
    //   Temperature: 'body_temperature'
    // },
    // excel_export_meta: [
    //   [
    //     {
    //       key: 'charset',
    //       value: 'utf-8'
    //     }
    //   ]
    // ]
  }),
  components: {},
  created() {
    const d = new Date()
    // d.setDate(d.getDate() - 1)
    const n = d
      .toLocaleDateString('id')
      .split('/')
      .reverse()
      .join('-')
    this.start_date = n
    this.end_date = n
    this.company = this.getUserProfile.employee.company.id
    this.dropdownCompany()
    this.initDataTable()
  },
  computed: {
    ...mapGetters(['getIsLoading', 'getUserProfile'])
  },
  methods: {
    ...mapActions([]),
    ...mapMutations(['setIsLoading']),

    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    dropdownCompany() {
      const application = this.getUserProfile.level
      // const result = application.find(({ application }) => application.id === 1)
      const result = application.find(({ id }) => id === '1' || id === '6')
      if (result !== undefined) {
        this.dropDownCompany = [
          { id: 0, name: 'Company' },
          { id: 1, name: 'IMP' },
          { id: 2, name: 'TMP' },
          { id: 3, name: 'GIT' },
          { id: 4, name: 'KAJ' },
          { id: 5, name: 'WIM' },
          { id: 6, name: 'SMI' },
          { id: 7, name: 'THP' }
        ]
      } else {
        this.dropDownCompany = [
          {
            id: this.getUserProfile.sanqua_company_id,
            name: this.getUserProfile.sanqua_company_name
          }
        ]
      }
    },
    searchEnter() {
      this.initDataTable()
    },
    initDataTable() {
      axios
        .get(
          `${this.attendance}access_door/log/list?start_date=${this.start_date}&end_date=${this.end_date}&keyword=${this.paramAPI.keyword}&company_id=${this.company}`
        )
        .then(res => {
          console.log(res)
          this.result = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    // exportReport() {
    //   axios
    //     .get(
    //       `${this.attendance}report/attendance/generateToExcel?start_date=${this.start_date}&end_date=${this.end_date}&company_id=${this.company}`
    //     )
    //     .then(res => {
    //       console.log(res.data)
    //       this.recapitulationAttendances = []
    //       if (res.data.status_code === '00') {
    //         this.recapitulationAttendances = res.data.formed_data

    //         setTimeout(() => {
    //           document.getElementById('exportRecap').click()
    //         }, 500)
    //       }
    //     })
    //     .catch(err => {
    //       console.log(err)
    //     })
    // },
    rowClick(pValue) {},
    convertDate(raw) {
      if (raw !== null) {
        const year = raw.slice(0, raw.indexOf('-'))
        const month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
        const day = raw.slice(raw.lastIndexOf('-') + 1)
        // return (day + '-' + month + '-' + year).replace(/-/g, '/')
        return day + '-' + month + '-' + year
      }
    },

    getTemperatureStatus(pTemperature) {
      // if (pType === 'in') {
      if (pTemperature >= 37.3) {
        return 'red'
      } else {
        return ''
      }
      // }
    },

    getUseMaskStatus(pMask) {
      // if (pType === 'in') {
      if (pMask === 1) {
        return ''
      } else {
        return 'red'
      }
      // }
    },

    getUseMaskName(pMask) {
      // if (pType === 'in') {
      if (pMask === 1) {
        return 'YES'
      } else {
        return 'NO'
      }
      // }
    }
  }
}
</script>

<style lang="scss">
#attendanceReport {
  margin-bottom: 10px;
  .attendance-list-div {
    padding: 40px 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .attendance-list-toolbar-1 {
      .attendance-list-toolbar-div {
        width: 30%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .attendance-list-toolbar-2 {
      display: none;
    }
  }
}

.arch-add-new-btn {
  padding: 1px 4px;
  box-shadow: 1px 1px 1px rgb(134, 132, 132);
  border: 1px solid #cacad4;
  cursor: pointer;
  margin-top: 5px;
  text-align: left;
  font-size: 12px;
  &:hover {
    box-shadow: 1px 2px 7px rgb(134, 132, 132);
    color: blue;
    text-decoration: underline;
  }
}

.arch-expDate {
  cursor: pointer;
  height: 40px;
  .arch-exp-date {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    .arch-exp-date-notif {
      overflow: hidden;
      width: 120px;
      position: absolute;
      left: 0;
      .exp-date-notif-div {
        display: flex;

        .exp-date-notif-chip1 {
          width: 95px;
          font-size: 10px;
        }
        .exp-date-notif-chip2 {
          font-size: 10px;
          .date-notif-chip2-p {
            width: 70px;
            margin: 0;
            margin: auto;
            text-align: center;
            // padding: 0 7px;
          }
        }
      }
    }
  }

  &:hover .arch-exp-date-notif {
    width: 0;
    transition: 0.5s;
  }
}
@media (max-width: 768px) {
  #attendanceReport {
    .attendance-list-div {
      .attendance-list-toolbar-1 {
        .attendance-list-toolbar-div {
          display: none;
        }
      }
      .attendance-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
